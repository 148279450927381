<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top">
                <h2><span>■</span> 신규신청 목록</h2>
            </div>
            <div class="con">
                <div class="con_table">
                    <table class="default_table mt-20">
                        <tbody v-if="loading">
                            <tr>
                                <th>No</th>
                                <th>등록일</th>
                                <th>기업명</th>
                                <th>고객명</th>
                                <th>희망지역</th>
                                <th>상담희망일정</th>
                                <th>상담유형</th>
                                <th>상담주제</th>
                                <th>신청</th>
                            </tr>
                            <tr>
                                <td colspan="8">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th>No</th>
                                <th>등록일</th>
                                <th>기업명</th>
                                <th>고객명</th>
                                <th>희망지역</th>
                                <th>상담희망일정</th>
                                <th>상담유형</th>
                                <th>상담주제</th>
                                <th>신청</th>
                            </tr>
                            <tr v-for="(item, index) of eapList" :key="index">
                                <td>{{item.rownum}}</td>
                                <td>{{item.createDate.slice(0,10)}}</td>
                                <td>{{item.companyName}}</td>
                                <td>{{item.name}}</td>
                                <td>{{item.eapHopeArea}} {{item.eapHopeArea2}}</td>
                                <td>{{item.eapHopeDate.slice(0,10)}}</td>
                                <td>{{item.typeName}}</td>
                                <td>{{item.subjectName}}</td>
                                <td><span v-if="item.status === '상담진행신청'" @click="apply(item.idx)" class="underline pointer">상담진행신청</span>
                                <span v-if="item.status === '대기'">대기</span>
                                <span v-if="item.status === '종료'">종료</span>
                                <span v-if="item.status === '확정'">확정 | </span>
                                <span v-if="item.status === '확정'" class="underline pointer" @click="register(item.idx)">상담예약 등록</span></td>
                            </tr>
                            <tr v-if="eapList.length === 0">
                                <td colspan="7">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
export default {
    data: () => ({
        eapList: [],

        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        loading:true,
    }),

    mounted() {
        this.getEapList()

    },

    methods: {

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getEapList()
            }
        },
        paging(index) {
            this.pageNum = index
            this.getEapList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getEapList()
            }
        },

        // 검색 api
        getEapList() {
            var params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            };

            this.loading = true;

            this.axios.get('/api/v1/center/eap/list', {
                    params: params
                })
                .then(res => {
                    if(res.data.err === 0) {
                        this.eapList = res.data.eapList
                        if (res.data.userList) {
                            this.listLength = res.data.eapListSize
                        }

                        this.idxCrmCenter = res.data.userList[0].idxCrmCenter
                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)
                    } else {
                        this.eapList = []
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                }).finally(()=>{
                    this.loading = false;
                });
        },

        apply(idx) {
            var params = new URLSearchParams();
            params.append('idxCrmClient', idx)

            this.axios.put('/api/v1/center/eap/apply', params)
                .then(res => {
                    alert('신청 되었습니다.')
                    this.$router.go(this.$router.currentRoute)
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })

        },

        // 상담예약 등록페이지로 이동
        register(idx) {
            this.$router.push(`/convention/consult_reserve_register?idx=${idx}`)
        },

    }
}
</script>
